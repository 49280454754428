<script lang="ts" setup>
import { computed } from "vue"; // Properties

// Properties
const props = defineProps({
    modelValue: {
        type: [String, Boolean, Number],
        default: "",
    },
    type: {
        type: String,
        default: "text",
    },
    errorMsg: {
        type: String,
        default: "",
    },
    showTooltip: {
        type: Boolean,
        default: false,
    },
});

// Data
const emit = defineEmits(["update:modelValue", "change", "blur"]);

const model = computed({
    get() {
        return props.modelValue;
    },
    set(value) {
        return emit("update:modelValue", value);
    },
});

// Functions
function onChange(event: any) {
    emit("change", event.target.value);
}

function onBlur(event: any) {
    emit("blur", event.target.value);
}
</script>

<template>
    <input
        v-model="model"
        v-tooltip="{
            content: errorMsg,
            shown: showTooltip ? true : false,
            triggers: [],
        }"
        :type="type"
        autofocus="autofocus"
        autocomplete="on"
        class="input-style"
        :class="!errorMsg ? 'border-gray-300 bg-gray-50' : 'border-red-700 bg-red-50'"
        @change="onChange"
        @blur="onBlur"
    />
</template>

<style scoped>
.input-style {
    @apply border px-3 py-1 shadow-none drop-shadow-none focus:border-gray-400 focus:outline-none;
}
</style>
